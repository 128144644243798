<script lang="ts" setup>
import type { HomeBanners } from "@/types";

defineProps<{ banner: HomeBanners[number] }>();

const { open } = useTaoModals();

const { playMagicAudio } = useMagicBoxAudios();

const handleOpenMagicBox = () => {
	open("LazyOModalMagicBoxes");
	playMagicAudio();

	dispatchGAEvent({
		event: "open_popup",
		location: "main_promotion",
		buttonName: "dailywheel"
	});
};

const PRIZE = {
	coins: 275000,
	entries: 500
};
</script>
<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:bg-color="banner.background"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
		@click="handleOpenMagicBox"
	>
		<template #default>
			<AText class="banner-title" type="base h7-md" :modifiers="['uppercase', 'medium', 'italic']">
				<p v-html="banner.title" />
			</AText>
		</template>
		<template #description>
			<div v-if="banner" class="app-banner__info">
				<div class="app-banner__desc-money app-banner__desc-money--coins">
					<NuxtImg
						class="app-banner__desc-ico app-banner__desc-ico--coins"
						src="/nuxt-img/banners/coins.png"
						format="webp"
					/>
					<div class="app-banner__sum app-banner__sum--coins">
						<AText type="h4 h2-md">{{ numberFormat(PRIZE.coins) }}</AText>
					</div>
				</div>
				<div class="app-banner__desc-money app-banner__desc-money--entries">
					<NuxtImg
						class="app-banner__desc-ico app-banner__desc-ico--entries"
						src="/nuxt-img/banners/entries.png"
						format="webp"
					/>
					<div class="app-banner__sum app-banner__sum--entries">
						<AText type="h4 h2-md">{{ numberFormat(PRIZE.entries) }} </AText>
					</div>
				</div>
			</div>
		</template>

		<template #actions>
			<AButton variant="primary" size="md" class="app-banner__btn">
				{{ banner.buttonText }}
			</AButton>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
.app-banner {
	&__info {
		display: flex;
		flex-direction: column;
		gap: gutter(0.5);
	}
}
</style>
